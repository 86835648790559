import React from 'react'
import MetaData from '../../components/MetaData'
import PageWrapper from '../../components/PageWrapper'

import Hero from '../../sections/common/Hero'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Text, Box, Title } from '../../components/Core'
import { useStaticQuery, graphql } from 'gatsby'
import ReviewStars from '../../assets/image/svg/reviewStars.svg'
import Link from '../../components/Link'
// import * as styles from '../sections/landing1/Home.module.css';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const Quote = ({ type, title, link, children, quoteRef, ...rest }) => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const { i18n } = useTranslation()
  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  const { t } = useTranslation()
  if (type === 'link') {
    return (
      <Link to={`/${lang}/${link}`} className="mb-2">
        <Box
          border="1px solid"
          borderColor="border"
          borderRadius={10}
          bg={userDarkMode ? 'dark' : 'white'}
          py="25px"
          px="30px"
          mb={4}
          {...rest}
        >
          <Title
            color={userDarkMode ? 'primaryLight' : 'primary'}
            variant="card"
          >
            {title}
          </Title>
          <Text
            className="mb-2"
            variant="small"
            color={userDarkMode ? 'light' : 'dark'}
          >
            "{children}"
          </Text>
          <Text
            variant="small"
            color={userDarkMode ? 'primaryLight' : 'primary'}
          >
            {t('testimonialsPage.readTestimonial')}
          </Text>
        </Box>
      </Link>
    )
  }
  return (
    <Box
      border="1px solid"
      borderColor="border"
      borderRadius={10}
      bg={userDarkMode ? 'dark' : 'white'}
      py="25px"
      px="30px"
      mb={4}
      {...rest}
    >
      <img
        width="110px"
        height="auto"
        className="mb-4 mt-2"
        src={ReviewStars}
        alt=""
        role="presentation"
      />
      <Title color={userDarkMode ? 'light' : 'dark'} variant="card">
        {title}
      </Title>
      <Text
        color={userDarkMode ? 'light' : 'dark'}
        variant="small"
        className="mb-2"
      >
        {children}
      </Text>
      <Text color={userDarkMode ? 'lightShade' : 'darkShade'} variant="small">
        {quoteRef}
      </Text>
    </Box>
  )
}

const Testimonials = () => {
  const { t } = useTranslation()
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return (
    <>
      <MetaData page="default" />

      <PageWrapper headerDark footerDark>
        <Hero title={t('testimonialsPage.heroTitle')}></Hero>
        <Section
          pt={[3, 4, 4, 5]}
          bg={userDarkMode ? 'black' : 'bg'}
          className="position-relative"
        >
          <Container>
            <div className="align-items-center">
              <Row>
                <Col lg="4">
                  <Quote
                    type="link"
                    link={`testimonials/bitwala`}
                    title="Bitwala"
                  >
                    {t('testimonialsPage.bitwalaCardText')}
                  </Quote>
                  <Quote
                    type="link"
                    link={`testimonials/intelsath`}
                    title="Intelsath LLC"
                  >
                    {t('testimonialsPage.intelsathCardText')}
                  </Quote>
                  <Quote
                    type="text"
                    quoteRef="Ahmed Hasan, K2 Global"
                    title={t('testimonialsPage.lovingCoinigy')}
                  ></Quote>
                </Col>
                <Col lg="4">
                  <Quote
                    quoteRef="iOS App. Brandon T."
                    type="text"
                    title={t('testimonialsPage.firstReviewTitle')}
                  >
                    {t('testimonialsPage.firstReview')}
                  </Quote>
                  <Quote
                    quoteRef="iOS App. Abdul Y."
                    type="text"
                    title={t('testimonialsPage.secondReviewTitle')}
                  >
                    {t('testimonialsPage.secondReview')}
                  </Quote>
                  <Quote
                    quoteRef="Android App. A-Omelihu N."
                    type="text"
                    title={t('testimonialsPage.thirdReviewTitle')}
                  >
                    {t('testimonialsPage.thirdReview')}
                  </Quote>
                </Col>
                <Col lg="4">
                  <Quote
                    quoteRef="iOS App. James R."
                    type="text"
                    title={t('testimonialsPage.fourthReviewTitle')}
                  >
                    {t('testimonialsPage.fourthReview')}
                  </Quote>
                  <Quote
                    quoteRef="iOS App. Abdul Y."
                    type="text"
                    title={t('testimonialsPage.fithReviewTitle')}
                  >
                    {t('testimonialsPage.fithReview')}
                  </Quote>
                  <Quote
                    quoteRef="Android App. Joel B."
                    type="text"
                    title={t('testimonialsPage.sixthReviewTitle')}
                  >
                    {t('testimonialsPage.sixthReview')}
                  </Quote>
                </Col>
              </Row>
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default Testimonials
